import React from 'react';
import styled from 'react-emotion';
import t from '@jetshop/intl';
import { ReactComponent as CircleCheckSVG } from '../../svg/CircleCheck.svg';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const CircleCheckIcon = styled(CircleCheckSVG)`
margin-right .75rem;
fill: #fff;
${({ theme }) => theme.below.md} {
  // width: 4vw;
  // height: 4vw;
  // max-width: 1rem;
  // margin-right 2vw;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
`;

const USPItem = styled('div')`
  display: flex;
  align-items: center;
  padding: 1.1em 0;
  margin: 0 1.75rem;
  font-size: 0.9375rem;
  color: #fff;
  ${({ theme }) => theme.below.md} {
    //font-size: 2.8vw;
    //margin: 0 2vw;
    font-size: 11px;
    margin: 0 5px;
  }
  @media (max-width: 321px) {
    font-size: 9px;
    margin: 0 5px;
  }
`;

export default () => {
  return (
    <ChannelContext.Consumer>
      {({ channels, selectedChannel, updateChannel }) => (
        <>
          <USPItem>
            <CircleCheckIcon />
            {t('Free delivery above {deliveryLimit} SEK', {
              deliveryLimit:
                selectedChannel.countrySettings.shipping.freeShippingLimit
                  .incVat
            })}
          </USPItem>
          <USPItem>
            <CircleCheckIcon />
            {t('Fast delivery')}
          </USPItem>
          <USPItem>
            <CircleCheckIcon />
            {t('Safe payment')}
          </USPItem>
        </>
      )}
    </ChannelContext.Consumer>
  );
};
